class Hero extends Component {
  state = { includeVideo: false, videoIsLoaded: false }

  componentDidMount = () => {
    if (
      this.props.video &&
      window.matchMedia(`(min-width: 48em)`).matches &&
      !is.edge()
    ) {
      this.setState({ includeVideo: true })
      this.waitForVideoLoad()
    }
  }

  waitForVideoLoad = () => {
    this.video = document.querySelector('video')
    this.video.addEventListener('canplaythrough', this.showVideo(), false)
  }

  showVideo = () => this.setState({ videoIsLoaded: true })

  componentWillUnmount = () => {
    if (this.video) {
      this.video.removeEventListener('canplaythrough', this.showVideo(), false)
    }
  }

  render() {
    const { title, tagline, link, image, video } = this.props
    const { includeVideo, videoIsLoaded } = this.state

    return (
      <section className="relative min-vh-100 max-h7">
        <div className="hero-padding relative z-1 container flex items-end min-vh-100 max-h7">
          <div className="white">
            <h1 className="page-heading mb3" style={{ maxWidth: `16ch` }}>
              {title.white}
              <br />
              <span>{title.white2}</span>
              <br />
              <span className="light-yellow">{title.yellow}</span>
            </h1>

            <p
              className="mb4 lh-title f4 fw7 ttu"
              style={{
                maxWidth: `36ch`
              }}
            >
              {tagline}
            </p>

            <ScrollTo
              href={link.href}
              className="link-white heading f4 light-yellow"
            >
              {link.text}
            </ScrollTo>
          </div>
        </div>

        <Img
          fluid={image.file.childImageSharp.fluid}
          alt={image.alt}
          objPosition={image.objPosition}
          className="fill"
          style={{ position: 'absolute', zIndex: -1 }}
        />

        {video && (
          <div
            className={`${includeVideo && videoIsLoaded ? `db` : `dn`} 
            } absolute fill z--1 overflow-hidden`}
          >
            <video
              preload="true"
              playsInline={true}
              autoPlay={true}
              loop={true}
              muted={true}
              className={`absolute top-0 left-0 bottom-0 right-0 w-100 h-100`}
              style={{ objectFit: `cover`, objectPosition: `10% 50%` }}
            >
              <source src={videoWebM} type="video/webm" />
              <source src={videoMp4} type="video/mp4" />
            </video>
          </div>
        )}

        <div className="absolute fill bg-black-40" />
      </section>
    )
  }
}

/*
 * 
 * Imports & Exports
 * 
 */

import React, { Component } from 'react'
import is from 'is_js'

import Img from '../components/Img'
import ScrollTo from '../components/ScrollTo'

import videoMp4 from '../videos/home-video-web.mp4'
import videoWebM from '../videos/home-video.webm'

export default Hero
