const Contact = ({ stamp, heading, invitation, services, successMessage }) => (
  <section id="contact" className="contact-grid container pv5 sm:mv3 md:mv4 lg:mv5">
    <Stamp largeText={stamp.largeText} smallText={stamp.smallText} />

    <div>
      <h2 className="section-subheading mt4 pt3 mb3 sm:pb2">{heading}</h2>

      <ContactForm
        invitation={invitation}
        services={services}
        successMessage={successMessage}
      />
    </div>
  </section>
)

/*
 *
 * Stamp
 *
 */

const Stamp = ({ largeText, smallText }) => (
  <div
    className="stamp flex justify-center items-center relative ml-auto mr-auto br-100 b--near-white"
    style={{ borderWidth: `.75rem`, width: `15rem`, height: `15rem` }}
  >
    <p className="heading tc">
      <span className="db mb2 lh-solid" style={{ fontSize: `1.75rem` }}>
        {largeText}
      </span>
      <span>{smallText}</span>
    </p>

    <aside
      className="flex justify-center items-center absolute top-0 right-0 bw3 br-100 b--white bg-dark-green"
      style={{
        transform: `translate(10%, -10%)`,
        width: `4.75rem`,
        height: `4.75rem`
      }}
    >
      <RollerSVG className="w-90 mb1" />
    </aside>
  </div>
)

/*
 *
 * Contact Form
 *
 */

// See repo: https://github.com/jaredpalmer/formik
// See docs: https://jaredpalmer.com/formik/docs/api/formik

class ContactForm extends Component {
  state = { sentSuccessfully: false }

  render() {
    const { invitation, services, successMessage } = this.props
    const { sentSuccessfully } = this.state

    return (
      <>
        {sentSuccessfully ? (
          <Success message={successMessage} />
        ) : (
          <>
            <p className="mb3 sm:pb2 md:pb3 measure lh-copy">{invitation}</p>

            <Formik
              initialValues={{
                Name: '',
                Email: '',
                Phone: '',
                Address: '',
                Services: []
              }}
              validationSchema={ValidationSchema}
              validateOnBlur={false}
              onSubmit={async values => {
                if (typeof window.fetch !== `undefined`) {
                  const sentSuccessfully = await submitFormToNetlify(
                    'Contact',
                    values
                  )
                  this.setState({ sentSuccessfully })
                } else {
                  console.log(
                    `🚧 Fetch is not supported in this browser. Fix that first!`
                  )
                }
              }}
            >
              {({ values, isSubmitting }) => (
                <Form name="Contact" netlify="true" netlify-honeypot="bot-field">
                  {/* Required by Netlify to track form name */}
                  <input type="hidden" name="form-name" value="Contact" />

                  {/* Honeypot */}
                  <input name="bot-field" hidden />

                  {/* Input copies to control input order in the submissions */}
                  <input type="text" name="Name" hidden />
                  <input type="email" name="Email" hidden />
                  <input type="tel" name="Phone" hidden />
                  <textarea name="Address" hidden />
                  <input type="checkbox" name="Services" hidden />

                  {/* Checkboxes - using the FieldArray so I can validate at least one chosen */}
                  {/* See: https://codesandbox.io/s/o5pw1vx916 */}
                  <fieldset>
                    <legend className="sr-only">
                      Select the services you are looking for
                    </legend>

                    <div className="checkbox-grid">
                      <FieldArray
                        name="Services"
                        render={arrayHelpers => (
                          <>
                            {services.map(service => (
                              <div
                                key={service.node.heading}
                                className="heading mt3"
                              >
                                <label
                                  key={service.node.heading}
                                  htmlFor={service.node.heading}
                                  className="custom-checkbox"
                                >
                                  <input
                                    name="Services"
                                    type="checkbox"
                                    value={service.node.heading}
                                    id={service.node.heading}
                                    checked={values.Services.includes(
                                      service.node.heading
                                    )}
                                    onChange={e => {
                                      if (e.target.checked)
                                        arrayHelpers.push(service.node.heading)
                                      else {
                                        const index = values.Services.indexOf(
                                          service
                                        )
                                        arrayHelpers.remove(index)
                                      }
                                    }}
                                  />
                                  {/* Styled "checkbox" */}
                                  <span className="checkmark" />

                                  {/* Visible text label */}
                                  <span className="checkbox-label">
                                    {service.node.heading}
                                  </span>
                                </label>
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  </fieldset>
                  <ErrorMessage
                    name="Services"
                    component="p"
                    className="input-error"
                  />

                  <div className="input-grid">
                    <div>
                      <Field
                        type="text"
                        name="Name"
                        aria-label="Name"
                        placeholder="Name"
                        className="input mt4"
                      />
                      <ErrorMessage
                        name="Name"
                        component="p"
                        className="input-error"
                      />
                    </div>

                    <div>
                      <Field
                        type="email"
                        name="Email"
                        aria-label="Email"
                        placeholder="Email"
                        className="input mt3"
                      />
                      <ErrorMessage
                        name="Email"
                        component="p"
                        className="input-error"
                      />
                    </div>

                    <div>
                      <Field
                        type="tel"
                        name="Phone"
                        aria-label="Phone"
                        placeholder="Phone"
                        className="input mt3"
                      />
                      <ErrorMessage
                        name="Phone"
                        component="p"
                        className="input-error"
                      />
                    </div>

                    <div>
                      <Field
                        name="Address"
                        component="textarea"
                        aria-label="Address"
                        placeholder="Address"
                        className="input mt3"
                      />
                      <ErrorMessage
                        name="Address"
                        component="p"
                        className="input-error"
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="link heading db mt4 sm:pt2 f4 yellow"
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    )
  }
}

const submitFormToNetlify = async (formName, formValues) => {
  let submitted

  await fetch(`/?no-cache=1`, {
    method: `POST`,
    headers: { 'Content-Type': `application/x-www-form-urlencoded` },
    body: createURL({ 'form-name': formName, ...formValues })
  })
    .then(() => {
      submitted = true
    })
    .catch(error => {
      console.log(`🚧 Form submission error:`, error)
      submitted = false
    })

  return submitted
}

// Create the URL encoding for the form submission
const createURL = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
    .join(`&`)
    .replace(/%2C/gi, '%2C%20') // add a space after any comma

// See: https://github.com/jquense/yup
const ValidationSchema = Yup.object().shape({
  Name: Yup.string().required('Please enter your name'),
  Email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please include your email address'),
  Phone: Yup.string()
    .min(7, 'Please enter a valid phone number')
    .required('Please include your phone number'),
  Address: Yup.string()
    .min(10, 'Please enter your full address')
    .required('Please include your address'),
  Services: Yup.array()
    .of(Yup.string())
    .required('Please select at least one service')
})

/* 
 *
 * Success
 * 
 */

const Success = ({ message }) => <p className="measure lh-copy">{message}</p>

/*
 *
 * Imports & Exports
 * 
 */

// See: https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/

import React, { Component } from 'react'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { ReactComponent as RollerSVG } from '../svg/roller.svg'

export default Contact
