class Base extends Component {
  // state = { isNotIE: null }

  // componentDidMount = () => this.setState({ isNotIE: !is.ie() })

  render() {
    const { children } = this.props
    // const { isNotIE } = this.state

    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                description
                language
                locale
                title
                twitterHandle
                siteUrl
              }
            }
            linksYaml {
              phone {
                tollFree {
                  href
                  text
                  label
                }
                local {
                  href
                  text
                  label
                }
              }
              email {
                href
                text
                label
                pitch
              }
              address {
                title
                street
                city
                href
              }
              nav {
                href
                text
              }
              social {
                href
                text
              }
            }
            allServiceListYaml {
              edges {
                node {
                  heading
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <SiteMetadata site={data.site.siteMetadata} />

            {/* Need to load warning by default (and then hide in non-IE browsers) because IE is not triggering switch to warning otherwise */}
            {/* {isNotIE ? ( */}
            <>
              <Header
                phone={data.linksYaml.phone}
                email={data.linksYaml.email}
                navLinks={data.linksYaml.nav}
              />

              {children}

              <Footer
                services={data.allServiceListYaml.edges}
                phone={data.linksYaml.phone}
                email={data.linksYaml.email}
                address={data.linksYaml.address}
                socialLinks={data.linksYaml.social}
              />
            </>
            {/* ) : (
              <BrowserWarning title="Kings Valley Paving" />
            )} */}

            <StructuredData site={data.site.siteMetadata} />
          </>
        )}
      />
    )
  }
}

/*
 *
 * Global styles & preloaded static assets (e.g. above-the-fold images, audio, video, and self-hosted fonts not handled by subfont)
 *
 */

import '../styles/index.css'
import muktaBold from '../fonts/mukta-v5-latin-700.woff2'
import muktaRegular from '../fonts/mukta-v5-latin-regular.woff2'
import ultraRegular from '../fonts/ultra-v10-latin-regular.woff2'

/*
 *
 * Metadata
 *
 */

// See: https://github.com/nfl/react-helmet + https://megatags.co + https://gethead.info

import siteImage from '../images/kings-valley-paving.jpg'

const SiteMetadata = ({ site }) => (
  <Helmet>
    {/* HTML language */}
    <html itemScope itemType="http://schema.org/WebPage" lang={site.language} />

    {/* Title comes first (meta charset and viewport are automatically included) */}
    <title itemProp="name" lang={site.language}>
      {site.title}
    </title>

    {/* Search engine */}
    <meta name="description" content={site.description} />
    <meta name="image" content={site.siteUrl + siteImage} />
    <link rel="canonical" href={site.siteUrl} />

    {/* Preconnect to CDNs for GSAP, GA-Lite and Google Analytics */}
    <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
    {/* <link rel="preconnect" href="https://cdn.jsdelivr.net" /> */}
    <link rel="preconnect" href="https://www.googletagmanager.com" />
    <link rel="preconnect" href="https://www.gstatic.com" />
    <link rel="preconnect" href="https://www.googleadservices.com" />
    <link rel="preconnect" href="https://www.google-analytics.com" />

    {/* Preload above-the-fold static assets */}
    <link
      rel="preload"
      href={ultraRegular}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href={muktaBold}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href={muktaRegular}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />

    {/* Schema.org for Google */}
    <meta itemProp="name" content={site.title} />
    <meta itemProp="description" content={site.description} />
    <meta itemProp="image" content={site.siteUrl + siteImage} />

    {/* Twitter */}
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={site.title} />
    <meta name="twitter:description" content={site.description} />
    <meta name="twitter:image:src" content={site.siteUrl + siteImage} />

    {/* Open Graph general (Facebook, Pinterest, Slack & Google+) */}
    <meta property="og:title" content={site.title} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={site.siteUrl} />
    <meta property="og:image" content={site.siteUrl + siteImage} />
    <meta property="og:description" content={site.description} />
    <meta property="og:site_name" content={site.title} />
    <meta property="og:locale" content={site.locale} />

    {/* Non-essential, but required for analytics */}
    {site.facebookAppId && (
      <meta property="fb:app_id" content={site.facebookAppId} />
    )}
    {site.twitterHandle && (
      <meta name="twitter:site" content={site.twitterHandle} />
    )}
  </Helmet>
)

/*
 *
 * Basic Structured Data
 *
 */

const StructuredData = ({ site }) => {
  const structuredData = `{
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "@id": "${site.siteUrl}",
    "name": "Kings Valley Paving",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "3230 King Vaughan Road, Unit #1",
      "addressLocality": "Maple",
      "addressRegion": "ON",
      "postalCode": "L6A 2A9",
      "addressCountry": "CA"
    },
    "url": "${site.siteUrl}",
    "email": "mailto:info@kingsvalleypaving.com",
    "telephone": "+18448575464",
    "image": "${site.siteUrl + siteImage.replace(`js/../`, ``)}",
    "sameAs": [
      "https://www.facebook.com/kingsvalleypaving",
      "https://twitter.com/KingsPaving",
      "https://www.instagram.com/Kingsvalleypavingtoronto",
      "https://www.youtube.com/channel/UCCwwOazTvD1yU-VkkiaSjZA",
      "https://www.linkedin.com/company/kings-valley-paving-inc/"
    ]
  }`

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: structuredData }}
    />
  )
}

/*
 *
 * Imports & Exports
 *
 */

import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
// import is from 'is_js'

// import BrowserWarning from '../components/BrowserWarning'
import Header from '../sections/Header'
import Footer from '../sections/Footer'

export default Base
